var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Filter","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('TokensForm',{attrs:{"tokenState":_vm.tokenState}}),_c('TokenEdit',{attrs:{"tokenState":_vm.tokenState}}),_c('div',[_c('es-btn',{attrs:{"disabled":_vm.busy,"color":"indigo"},on:{"click":_vm.refresh}},[_c('fa',{attrs:{"icon":"sync"}}),_vm._v(" Refresh")],1)],1)],1),_c('v-card',{staticClass:"mb-6"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"header-props":{
        sortIcon: 'mdi-chevron-down'
      },"hide-default-footer":"","fixed-header":"","height":"70vh","sort-by":"valid_to","item-class":"status","must-sort":true,"items-per-page":1000,"loading":_vm.busy,"loading-text":_vm.loadingText,"no-data-text":_vm.noDataText,"search":_vm.search},scopedSlots:_vm._u([{key:"item.percentage_discount",fn:function(ref){
      var item = ref.item;
return [(item.percentage_discount)?_c('span',[_vm._v(_vm._s(((item.percentage_discount) + "%")))]):_c('span',[_vm._v("--")])]}},{key:"item.valid_from",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted_valid_from)+" ")]}},{key:"item.valid_to",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted_valid_to)+" ")]}},{key:"item.quotas.enforce_quantity",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.quotas.enforce_quantity ? "Yes" : "No")+" ")]}},{key:"item.endorsements_template_filename",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.endorsements_template_filename ? "Yes" : "No")+" ")]}},{key:"item.hybrid_assessment",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.hybrid_assessment ? "Yes" : "No")+" ")]}},{key:"item.kyc_required",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.kyc_required ? "Yes" : "No")+" ")]}},{key:"item.invite_only",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.invite_only ? "Yes" : "No")+" ")]}},{key:"item.license_type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.license_type_name || "--")+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"actions d-flex"},[_c('es-btn',{staticClass:"es-btn es-btn-copy ml-auto",attrs:{"title":("Edit " + (item.code)),"color":"teal"},on:{"click":function($event){return _vm.editToken(item.voucher_id)}}},[_c('fa',{attrs:{"icon":"pencil-alt"}})],1),_c('es-btn',{staticClass:"es-btn es-btn-copy",attrs:{"title":("Copy " + (item.code) + " to clipboard"),"color":"primary"},on:{"click":function($event){return _vm.copyToClipboard(item.code)}}},[_c('fa',{attrs:{"icon":"copy"}})],1),_c('es-btn',{staticClass:"es-btn es-btn-remove",attrs:{"title":item.status === 'expired'
                ? ((item.code) + " is expired.")
                : ("Remove " + (item.code)),"disabled":item.status === 'expired' || _vm.busy,"color":{ orange: item.status !== 'expired' }},on:{"click":function($event){return _vm.removeWithConfirm(item.code)}}},[_c('fa',{attrs:{"icon":"trash"}})],1)],1)]}}],null,true)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.busy),expression:"busy"}],staticClass:"dot-windmill",attrs:{"slot":"progress"},slot:"progress"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }